import React, { useEffect, useState } from 'react'
import { FiBookmark, FiChevronDown, FiHome, FiLogOut, FiMenu, FiUser } from 'react-icons/fi';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import BlueLogo from '../../../assets/images/img/hostelng_logo.png';
// import SantaLogo from '../../../assets/images/svg/hostel-logo-withsanta.svg';
import { useLogOut } from '../../../hooks/useLogOut';
import { useAuthContext } from '../../../hooks/useAuthContext';
import ContentLoader from 'react-content-loader';
import { useUserDetailsContext } from '../../../hooks/useUserDetailsContext';
import { useRef } from 'react';



export default function Header() {

    const resourceURL = process.env.REACT_APP_RESOURCE_URL;

    const navigate = useNavigate();
    const { user } = useAuthContext();

    const { user_details } = useUserDetailsContext();
    const [openMenu, setOpenMenu] = useState(false);
    const [openMobileMenu, setOpenMobileMenu] = useState(false);

    const dropDownRef = useRef(null);

    // call logout from useLogout hook
    const { logOut } = useLogOut()

    const handleLogOut = () => {
        logOut();
        navigate('/');
    }

    // Close dropdown menu on click outside
    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setOpenMenu(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [])

    const handleMenu = () => setOpenMenu(false);

    return (
        <div className='header'>
            <div className="container header-container">
                <nav className={clsx('navigation__left', openMobileMenu && 'open')}>

                    <Link to="/" ><img src={BlueLogo} alt="Logo" className='brandname' /> </Link>

                    <ul className={clsx('nav', openMobileMenu && 'open')} >
                        <li> <NavLink to="/rooms" className='nav-link'>Lease a room</NavLink> </li>
                        <li> <NavLink to="/host" className='nav-link'>Become a Host </NavLink> </li>
                        <li> <NavLink to="/book-travel-bus" className='nav-link'>
                            Book travel bus 
                            <span className='nav-link--new'>New</span>
                        </NavLink>
                        </li>
                        <li> <a href='https://development.hostel.ng/blog/' className='nav-link'> Blog </a> </li>
                        <li> <NavLink to="/faqs" className='nav-link'> FAQs </NavLink> </li>

                        {!user && <button onClick={() => navigate('/signup')} className='zbtn zbtn-primary-def create-account'>Create Free Account</button>}
                    </ul>

                </nav>

                <div className='navigation__right' ref={dropDownRef}>
                    {!user &&
                        <div className='actions'>
                            <button onClick={() => navigate('/login')} className='zbtn zbtn-primary-outline-def signin'>Sign In</button>
                            <button onClick={() => navigate('/signup')} className='zbtn zbtn-primary-def create-account'>Create Free Account</button>
                        </div>
                    }

                    {/* Desktop view */}
                    {user &&
                        <div className='auth__wrapper' >
                            <span className='welcome'>Hey, {user_details?.fName}</span>
                            <div className="avatarbox" onClick={() => setOpenMenu(!openMenu)}>
                                {user_details.avatar ? <div className="avatar">
                                    {user_details.avatar && user_details.avatar === "default.png" ?
                                        <FiUser size="2rem" className='icon' /> :
                                        <img src={`${resourceURL}/${user_details.avatar}`} alt="avatar" className='user__profile' />
                                    }
                                </div> : <div className="avatar">
                                    <ContentLoader
                                        speed={2}
                                        width={42}
                                        height={42}
                                        viewBox="0 0 42 42"
                                        backgroundColor="#F1F2F3"
                                        foregroundColor="#F8F8F9"
                                        style={{ width: "100%" }}
                                    >
                                        <circle cx="5" cy="6" r="100" />
                                    </ContentLoader>
                                </div>}

                                <FiChevronDown size="1.8rem" className='icon toggle' />
                            </div>
                        </div>
                    }

                    {openMenu &&
                        <div className="menu__dropdown">
                            <ul>
                                {user_details.hostel_count > 0 && <li onClick={handleMenu}>
                                    <Link to="/my-rooms" className='anchor'><FiBookmark className='icon' />  My rooms</Link>
                                </li>}

                                <li onClick={handleMenu}>
                                    <Link to="/reserved-rooms" className='anchor'><FiHome className='icon' /> Reserved room</Link>
                                </li>

                                {/* <li onClick={handleMenu}>
                                    <Link to="/profile" className='anchor'><FiUsers className='icon' /> Invite friends</Link>
                                </li> */}


                                <li onClick={handleMenu}>
                                    <Link to="/profile" className='anchor'><FiUser className='icon' /> Profile</Link>
                                </li>

                                <div className="separator"></div>

                                <li onClick={handleMenu}>
                                    <div onClick={handleLogOut}>
                                        <span className='anchor'><FiLogOut className='icon' /> Logout</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    }

                    <div className="mobile__menu" onClick={() => setOpenMobileMenu(!openMobileMenu)}>
                        <FiMenu size="2.5rem" className='icon' />
                    </div>
                </div>


            </div>
        </div>
    )
}
